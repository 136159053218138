.border {
   top: 50%;
}
.sectionNumber {
    color: rgba(0, 255, 136, 0.9);
    font-weight: 500;
    font-size: 1em;
}

.contact {
    height: 200px;
    width: 600px;
    top: 50%;
    margin-top: -100px;
    color: rgb(136, 146, 176);
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.main {
    color: rgb(136, 146, 176);
    text-align: center;
}

p {
    color: rgb(195, 201, 219);
}
