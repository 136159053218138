body {
    background-color: red;
}

.banner {
    display: flex;
    align-items: center;
    position: fixed;
    top: 70%;
    transform: rotate(90deg);
    font-weight: 700;
    letter-spacing: 1.5px;
    font-size: 0.9rem;
    z-index: 8;
    color: rgb(136, 146, 176);
    right: -48px;
}

.banner:after {
    content: '';
    display: block;
    width: 130px;
    height: 2px;
    background: rgb(136, 146, 176);
    right: -150px;
    top: 50%;
    z-index: 6;
    position: absolute;
}
