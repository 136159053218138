body {
    font-family: 'Roboto Mono', monospace;
}

.container {
    justify-content: center;
    align-items: center;
    display: flex;
    /*max-width: 200px;*/
}
.sectionNumber {
    color: rgba(0, 255, 136, 0.9);
    font-weight: 500;
    font-size: 1em;
}

.projects {
    padding-bottom: 10px;
    color: #CCD6F6;
}

.projectsDisplay {
    margin: 100px 0px;
}

.list {
    list-style: none;
    padding: 1.3em;
    padding-left: 0px;
    display: inline;
    position: relative;
    font-size: 12px;
    color: rgb(136, 146, 176);
}



.ul {
    list-style: none;
    padding: 0;
}

.ulright {
    text-align: right;
}

.ulleft {
    text-align: left;
}

.image {
    max-width: 600px;
}

.desc {
    display: block;
    font-size: 15px;
    margin-top: 50px;
}

hr.style-one {
    border-top: 1px solid #fdfdfd;
}

h3 {
    font-weight: 400;
}

h3:hover {
    color: rgba(0, 255, 136, 0.6);

}

.icons {
    font-size: 25px;
    padding: 10px;
    color: #CCD6F6;
}

.icons:hover {
    color: rgba(0, 255, 136, 0.6);

}

.sample {
    font-size: 13px;
    color: rgba(0, 255, 136, 0.9);
}
