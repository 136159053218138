body {
    font-family: 'Roboto Mono', monospace;
    min-height: 100vh;
    position: relative;
}

.container {
    justify-content: center;
    align-items: center;
    display: flex;
    /*max-width: 200px;*/
}
.sectionNumber {
    color: rgba(0, 255, 136, 0.9);
    font-weight: 500;
    font-size: 1em;
}

.about {
    color: #CCD6F6;
    opacity: 1;
    padding-top: 80px;
}

.list {
    list-style: none;
    padding: 1.3em;
    display: inline-block;
    position: relative;
}

.list:before {
        content: "\f054"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
        color: rgba(0, 255, 136, 0.9);
}

.ul {
    list-style: none;
}

.ulright {
    text-align: right;
}

.image {
    max-width: 50%;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

hr.style-one {
    border-top: 1px solid #fdfdfd;
}

