body {
    font-family: 'Roboto Mono', monospace;
    min-height: 100vh;
    justify-content: center;
    padding-top: 12%;
}

.border {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 5%;
}

.greenFont {
    color: rgba(0, 255, 136, 0.9);
    font-size: 0.7em;
}

.jumbotron{
    background-color: #0a192f;
}

.name {    
    color: rgb(204, 214, 246);
}

.desc {
    color: rgb(136, 146, 176);
}

