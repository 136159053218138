body {
    font-family: 'Roboto Mono', monospace;
}

.greenFont {
    color: rgba(0, 255, 136, 0.9);    
}

.logo {
    color: rgba(0, 255, 136, 0.9);
    font-size: 2rem;
    padding-left: 20px;
}

#navbar {
    transition: top 0.3s;
}

body div div nav {
    background-color: #0a192f;
}
.button {
    color: rgba(0, 255, 136, 0.9);
}